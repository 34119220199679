import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import ReactCountryFlag from 'react-country-flag';
import { Link } from 'react-router-dom';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const timeRanges = [
  { label: 'Last 24 Hours', value: '24h' },
  { label: 'Last 7 Days', value: '7d' },
  { label: 'Last 30 Days', value: '30d' },
  { label: 'Last 90 Days', value: '90d' }
];

const TenantUsage = ({ stats, timeRange }) => {
  const [latestStats, setLatestStats] = useState(null);

  useEffect(() => {
    const fetchLatestStats = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/tenant-stats/latest`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) throw new Error('Failed to fetch latest stats');
        const data = await response.json();
        setLatestStats(data);
      } catch (error) {
        console.error('Error fetching latest stats:', error);
      }
    };

    fetchLatestStats();
  }, []);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3, // Make the graph shorter
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#E5E7EB'
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(context) {
            let value = context.raw;
            let label = context.dataset.label;
            
            // Format based on size
            if (label === 'Disk Usage') {
              if (value >= 1024 * 1024 * 1024) {
                value = (value / (1024 * 1024 * 1024)).toFixed(1) + ' GB';
              } else if (value >= 1024 * 1024) {
                value = (value / (1024 * 1024)).toFixed(1) + ' MB';
              } else if (value >= 1024) {
                value = (value / 1024).toFixed(1) + ' KB';
              } else {
                value = value + ' B';
              }
            } else {
              value = value.toLocaleString();
            }
            
            return `${label}: ${value}`;
          }
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: (() => {
            switch (timeRange) {
              case '24h': return 'hour';
              case '7d': return 'day';
              case '30d': return 'week';
              case '90d': return 'month';
              default: return 'hour';
            }
          })(),
          displayFormats: {
            hour: 'HH:mm',
            day: 'MMM d',
            week: 'MMM d',
            month: 'MMM yyyy'
          },
          tooltipFormat: 'PPpp'
        },
        display: true,
        grid: {
          color: 'rgba(229, 231, 235, 0.1)',
          display: true
        },
        ticks: {
          source: 'data',
          color: '#E5E7EB',
          maxRotation: 0,
          autoSkip: true,
          display: true,
          maxTicksLimit: (() => {
            switch (timeRange) {
              case '24h': return 12;
              case '7d': return 7;
              case '30d': return 6;
              case '90d': return 6;
              default: return 12;
            }
          })()
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        beginAtZero: true,
        grid: {
          color: 'rgba(229, 231, 235, 0.1)'
        },
        ticks: {
          color: '#E5E7EB',
          callback: (value) => value.toLocaleString()
        },
        title: {
          display: true,
          text: 'Record Count',
          color: '#E5E7EB'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        beginAtZero: true,
        grid: {
          drawOnChartArea: false
        },
        ticks: {
          color: '#E5E7EB',
          callback: (value) => {
            if (value >= 1024 * 1024 * 1024) {
              return (value / (1024 * 1024 * 1024)).toFixed(1) + ' GB';
            } else if (value >= 1024 * 1024) {
              return (value / (1024 * 1024)).toFixed(1) + ' MB';
            } else if (value >= 1024) {
              return (value / 1024).toFixed(1) + ' KB';
            }
            return value + ' B';
          }
        },
        title: {
          display: true,
          text: 'Disk Usage',
          color: '#E5E7EB'
        }
      }
    }
  };

  const chartData = {
    labels: stats.data.map(d => d.timestamp),
    datasets: [
      {
        label: 'Record Count',
        data: stats.data.map(d => d.metrics.record_count),
        borderColor: 'rgb(124, 58, 237)',
        backgroundColor: 'rgba(124, 58, 237, 0.5)',
        tension: 0.4,
        yAxisID: 'y'
      },
      {
        label: 'Disk Usage',
        data: stats.data.map(d => d.metrics.disk_usage),
        borderColor: 'rgb(52, 211, 153)',
        backgroundColor: 'rgba(52, 211, 153, 0.5)',
        tension: 0.4,
        yAxisID: 'y1'
      }
    ]
  };

  // Use latestStats for the progress bar if available, fallback to the last aggregated value
  const latestRecordCount = latestStats?.stats?.find(s => s.tenant_id === stats.tenant_id)?.metrics.record_count || 
                           stats.data[stats.data.length - 1]?.metrics.record_count || 0;
  const recordCountPercentage = Math.min((latestRecordCount / stats.maxRecords) * 100, 100);

  return (
    <div className="bg-component-bg rounded-lg p-6 shadow-sm mb-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h3 className="text-lg font-medium text-text">
            {stats.description}
          </h3>
          <p className="text-sm text-gray-400 flex items-center">
            {stats.country_code && (
              <ReactCountryFlag 
                countryCode={stats.country_code} 
                svg 
                style={{
                  width: '1.2em',
                  height: '1.2em',
                  marginRight: '0.5em'
                }}
              />
            )}
            {stats.location}
          </p>
        </div>
      </div>

      {/* Record Count Progress */}
      <div className="mb-6">
        <div className="flex justify-between mb-2">
          <h3 className="text-sm font-medium text-text">Record Count</h3>
          <span className="text-sm text-gray-400">
            {latestRecordCount.toLocaleString()} / {stats.maxRecords.toLocaleString()}
          </span>
        </div>
        <div className="w-full bg-gray-700 rounded-full h-2">
          <div
            className={`h-2 rounded-full ${
              recordCountPercentage > 90 ? 'bg-red-500' : 'bg-accent-purple'
            }`}
            style={{ width: `${recordCountPercentage}%` }}
          />
        </div>
        {recordCountPercentage > 90 && (
          <p className="mt-1 text-xs text-red-500">
            Warning: Approaching record limit
          </p>
        )}
      </div>

      {/* Usage Chart */}
      <div>
        <Line options={chartOptions} data={chartData} />
      </div>
    </div>
  );
};

const Usage = () => {
  const [timeRange, setTimeRange] = useState('24h');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);

  const getDateRange = (range) => {
    const end = new Date();
    const start = new Date();
    
    switch (range) {
      case '24h':
        start.setHours(start.getHours() - 24);
        break;
      case '7d':
        start.setDate(start.getDate() - 7);
        break;
      case '30d':
        start.setDate(start.getDate() - 30);
        break;
      case '90d':
        start.setDate(start.getDate() - 90);
        break;
      default:
        start.setHours(start.getHours() - 24);
    }
    
    return {
      start: start.toISOString(),
      end: end.toISOString()
    };
  };

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const { start, end } = getDateRange(timeRange);
        const token = localStorage.getItem('token');
        
        const response = await fetch(
          `/api/tenant-stats?start_date=${start}&end_date=${end}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );

        if (!response.ok) {
          if (response.status === 404) {
            setStats({ stats: [] });
            return;
          }
          throw new Error('Failed to fetch stats');
        }

        const data = await response.json();
        setStats(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [timeRange]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-accent-purple"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        Error loading usage data: {error}
      </div>
    );
  }

  // If no stats or empty stats array, show the empty state
  if (!stats?.stats || stats.stats.length === 0) {
    return (
      <div className="bg-component-bg rounded-lg p-8 text-center">
        <h3 className="text-xl font-medium text-text mb-4">No Usage Data Available</h3>
        <p className="text-gray-400 mb-6">
          You haven't created any API keys yet. Create your first API key to start tracking usage.
        </p>
        <Link 
          to="/dashboard/api-keys"
          className="inline-flex items-center px-4 py-2 bg-accent-purple text-white rounded-lg hover:bg-accent-purple/90 transition-colors"
        >
          Create API Key
        </Link>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Time Range Selector */}
      <div className="flex justify-end space-x-2">
        {timeRanges.map(range => (
          <button
            key={range.value}
            onClick={() => setTimeRange(range.value)}
            className={`px-4 py-2 rounded-lg text-sm font-medium ${
              timeRange === range.value
                ? 'bg-accent-purple text-white'
                : 'bg-component-bg text-text hover:bg-accent-purple/10'
            }`}
          >
            {range.label}
          </button>
        ))}
      </div>

      {/* Stats for each API key */}
      {stats.stats.map((tenantStats, index) => (
        <TenantUsage 
          key={tenantStats.tenant_id} 
          stats={tenantStats} 
          timeRange={timeRange}
        />
      ))}
    </div>
  );
};

export default Usage; 